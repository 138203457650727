import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home/areaChart`))} />
        {
          //Bitacoras
        }
        <Route path={`${APP_PREFIX_PATH}/control/nuevo`} component={lazy(() => import(`./bitacoras/control-calidad-materiaPrima/module-add`))} />
        <Route path={`${APP_PREFIX_PATH}/control/:id`} component={lazy(() => import(`./bitacoras/control-calidad-materiaPrima/module-edit`))} />
        <Route path={`${APP_PREFIX_PATH}/control`} component={lazy(() => import(`./bitacoras/control-calidad-materiaPrima/module`))} />

        <Route path={`${APP_PREFIX_PATH}/almacen/nuevo`} component={lazy(() => import(`./bitacoras/almacenamiento-producto-Terminado/module-add`))} />
        <Route path={`${APP_PREFIX_PATH}/almacen/:id`} component={lazy(() => import(`./bitacoras/almacenamiento-producto-Terminado/module-edit`))} />
        <Route path={`${APP_PREFIX_PATH}/almacen`} component={lazy(() => import(`./bitacoras/almacenamiento-producto-Terminado/module`))} />

        <Route path={`${APP_PREFIX_PATH}/lavado/nuevo`} component={lazy(() => import(`./bitacoras/lavado-enjuagado/module-add`))} />
        <Route path={`${APP_PREFIX_PATH}/lavado/:id`} component={lazy(() => import(`./bitacoras/lavado-enjuagado/module-edit`))} />
        <Route path={`${APP_PREFIX_PATH}/lavado`} component={lazy(() => import(`./bitacoras/lavado-enjuagado/module`))} />

        <Route path={`${APP_PREFIX_PATH}/limpiezaInstalaciones/nuevo`} component={lazy(() => import(`./bitacoras/limpieza-desinfeccion-instalaciones/module-add`))} />
        <Route path={`${APP_PREFIX_PATH}/limpiezaInstalaciones/:id`} component={lazy(() => import(`./bitacoras/limpieza-desinfeccion-instalaciones/module-edit`))} />
        <Route path={`${APP_PREFIX_PATH}/limpiezaInstalaciones`} component={lazy(() => import(`./bitacoras/limpieza-desinfeccion-instalaciones/module`))} />

        <Route path={`${APP_PREFIX_PATH}/limpiezaEquipo/nuevo`} component={lazy(() => import(`./bitacoras/limpieza-desinfeccion-equipo/module-add`))} />
        <Route path={`${APP_PREFIX_PATH}/limpiezaEquipo/:id`} component={lazy(() => import(`./bitacoras/limpieza-desinfeccion-equipo/module-edit`))} />
        <Route path={`${APP_PREFIX_PATH}/limpiezaEquipo`} component={lazy(() => import(`./bitacoras/limpieza-desinfeccion-equipo/module`))} />

        <Route path={`${APP_PREFIX_PATH}/fumigacion/nuevo`} component={lazy(() => import(`./bitacoras/fumigacion/module-add`))} />
        <Route path={`${APP_PREFIX_PATH}/fumigacion/:id`} component={lazy(() => import(`./bitacoras/fumigacion/module-edit`))} />
        <Route path={`${APP_PREFIX_PATH}/fumigacion`} component={lazy(() => import(`./bitacoras/fumigacion/module`))} />

        <Route path={`${APP_PREFIX_PATH}/higiene/nuevo`} component={lazy(() => import(`./bitacoras/revision-higiene/module-add`))} />
        <Route path={`${APP_PREFIX_PATH}/higiene/:id`} component={lazy(() => import(`./bitacoras/revision-higiene/module-edit`))} />
        <Route path={`${APP_PREFIX_PATH}/higiene`} component={lazy(() => import(`./bitacoras/revision-higiene/module`))} />
        {
          //Coleccion
        }
        <Route path={`${APP_PREFIX_PATH}/promociones/nuevo`} component={lazy(() => import(`./coleccion/promociones/module-add`))} />
        <Route path={`${APP_PREFIX_PATH}/promociones/:id/detalles`} component={lazy(() => import(`./coleccion/promociones/module-details`))} />
        <Route path={`${APP_PREFIX_PATH}/promociones/:id`} component={lazy(() => import(`./coleccion/promociones/module-edit`))} />
        <Route path={`${APP_PREFIX_PATH}/promociones`} component={lazy(() => import(`./coleccion/promociones/module`))} />

        <Route path={`${APP_PREFIX_PATH}/empresa/nuevo`} component={lazy(() => import(`./coleccion/empresa/module-add`))} />
        <Route path={`${APP_PREFIX_PATH}/empresa/:id/detalles`} component={lazy(() => import(`./coleccion/empresa/module-details`))} />
        <Route path={`${APP_PREFIX_PATH}/empresa/:id`} component={lazy(() => import(`./coleccion/empresa/module-edit`))} />
        <Route path={`${APP_PREFIX_PATH}/empresa`} component={lazy(() => import(`./coleccion/empresa/module`))} />

        <Route path={`${APP_PREFIX_PATH}/cupones/nuevo`} component={lazy(() => import(`./coleccion/cupones/module-add`))} />
        <Route path={`${APP_PREFIX_PATH}/cupones/:id/detalles`} component={lazy(() => import(`./coleccion/cupones/module-details`))} />
        <Route path={`${APP_PREFIX_PATH}/cupones/:id`} component={lazy(() => import(`./coleccion/cupones/module-edit`))} />
        <Route path={`${APP_PREFIX_PATH}/cupones`} component={lazy(() => import(`./coleccion/cupones/module`))} />

        <Route path={`${APP_PREFIX_PATH}/cliente/mapa`} component={lazy(() => import(`./coleccion/cliente/module-mapa`))} />
        <Route path={`${APP_PREFIX_PATH}/cliente/nuevo`} component={lazy(() => import(`./coleccion/cliente/module-add`))} />
        <Route path={`${APP_PREFIX_PATH}/cliente/:id/detalles`} component={lazy(() => import(`./coleccion/cliente/module-details`))} />
        <Route path={`${APP_PREFIX_PATH}/cliente/pedidos`} component={lazy(() => import(`./coleccion/cliente/module-pedidos`))} />
        <Route path={`${APP_PREFIX_PATH}/cliente/:id`} component={lazy(() => import(`./coleccion/cliente/module-edit`))} />
        <Route path={`${APP_PREFIX_PATH}/cliente`} component={lazy(() => import(`./coleccion/cliente/module`))} />

        <Route path={`${APP_PREFIX_PATH}/direcciones/nuevo`} component={lazy(() => import(`./coleccion/direcciones/module-add`))} />
        <Route path={`${APP_PREFIX_PATH}/direcciones/:id/detalles`} component={lazy(() => import(`./coleccion/direcciones/module-details`))} />
        <Route path={`${APP_PREFIX_PATH}/direcciones/:id`} component={lazy(() => import(`./coleccion/direcciones/module-edit`))} />
        <Route path={`${APP_PREFIX_PATH}/direcciones`} component={lazy(() => import(`./coleccion/direcciones/module`))} />

        <Route path={`${APP_PREFIX_PATH}/vendedor/nuevo`} component={lazy(() => import(`./coleccion/vendedor/module-add`))} />
        <Route path={`${APP_PREFIX_PATH}/vendedor/:id/detalles`} component={lazy(() => import(`./coleccion/vendedor/module-details`))} />
        <Route path={`${APP_PREFIX_PATH}/vendedor/:id`} component={lazy(() => import(`./coleccion/vendedor/module-edit`))} />
        <Route path={`${APP_PREFIX_PATH}/vendedor`} component={lazy(() => import(`./coleccion/vendedor/module`))} />

        {
          //Catalogos
        }
        <Route path={`${APP_PREFIX_PATH}/producto/nuevo`} component={lazy(() => import(`./catalogo/producto/module-add`))} />
        <Route path={`${APP_PREFIX_PATH}/producto/:id/detalles`} component={lazy(() => import(`./catalogo/producto/module-details`))} />
        <Route path={`${APP_PREFIX_PATH}/producto/:id`} component={lazy(() => import(`./catalogo/producto/module-edit`))} />
        <Route path={`${APP_PREFIX_PATH}/producto`} component={lazy(() => import(`./catalogo/producto/module`))} />

        <Route path={`${APP_PREFIX_PATH}/encuestas/nuevo`} component={lazy(() => import(`./catalogo/encuestas/module-add`))} />
        <Route path={`${APP_PREFIX_PATH}/encuestas/:id/detalles`} component={lazy(() => import(`./catalogo/encuestas/module-details`))} />
        <Route path={`${APP_PREFIX_PATH}/encuestas/:id`} component={lazy(() => import(`./catalogo/encuestas/module-edit`))} />
        <Route path={`${APP_PREFIX_PATH}/encuestas`} component={lazy(() => import(`./catalogo/encuestas/module`))} />

        {
          // Reporte
        }
        <Route path={`${APP_PREFIX_PATH}/reportes/reporte/:fechaIni/:fechaFini`} component={lazy(() => import(`./reportes/reporte/module`))} />
        <Route path={`${APP_PREFIX_PATH}/reportes/reporte/`} component={lazy(() => import(`./reportes/reporte/module`))} />
        {
          //Qr 
        }
        <Route path={`${APP_PREFIX_PATH}/qr/generar-by-url`} component={lazy(() => import(`./qr/generarQr/module`))} />
        <Route path={`${APP_PREFIX_PATH}/qr/generar-ids`} component={lazy(() => import(`./qr/registrar/module`))} />
        {
          //Whatsapp
        }
        <Route path={`${APP_PREFIX_PATH}/whatsapp/mensaje`} component={lazy(() => import(`./whatsapp/mensaje/module`))} />
        <Route path={`${APP_PREFIX_PATH}/whatsapp`} component={lazy(() => import(`./whatsapp/module`))} />
        {
          //Ventas
        }
        <Route path={`${APP_PREFIX_PATH}/ventas/pedidos`} component={lazy(() => import(`./ventas/pedidos/module`))} />
        <Route path={`${APP_PREFIX_PATH}/ventas/pedido/nuevo`} component={lazy(() => import(`./ventas/pedidos/module-add`))} />
        <Route path={`${APP_PREFIX_PATH}/ventas/pedido/:id/editar`} component={lazy(() => import(`./ventas/pedidos/module-edit`))} />
        <Route path={`${APP_PREFIX_PATH}/ventas/pedido/:id/detalles`} component={lazy(() => import(`./ventas/pedidos/module-read`))} />

        {
          //Reportes Clientes
        }
        <Route path={`${APP_PREFIX_PATH}/reportes/balance`} component={lazy(() => import(`./reportes/balanceIngresosEgresos/module`))} />
        <Route path={`${APP_PREFIX_PATH}/reportes/clientes-atencion`} component={lazy(() => import(`./reportes/clientesAtencion/module`))} />
        <Route path={`${APP_PREFIX_PATH}/reportes/clientes-nuevos/:fechaIni/:fechaFini`} component={lazy(() => import(`./reportes/clientesNuevos/module`))} />
        <Route path={`${APP_PREFIX_PATH}/reportes/clientes-nuevos`} component={lazy(() => import(`./reportes/clientesNuevos/module`))} />

        {
          //Promedio de compras por cliente
        }
        <Route path={`${APP_PREFIX_PATH}/reportes/promedio-compras/mapa`} component={lazy(() => import(`./reportes/promedioCompras/module-mapa`))} />
        <Route path={`${APP_PREFIX_PATH}/reportes/promedio-compras/detalles`} component={lazy(() => import(`./reportes/promedioCompras/module-details`))} />
        <Route path={`${APP_PREFIX_PATH}/reportes/promedio-compras`} component={lazy(() => import(`./reportes/promedioCompras/module`))} />

        {
          //Zonas basadas en promedio-compras
        }
        <Route path={`${APP_PREFIX_PATH}/reportes/zonas/mapa`} component={lazy(() => import(`./reportes/zonas/module-mapa`))} />
        <Route path={`${APP_PREFIX_PATH}/reportes/zonas/detalles`} component={lazy(() => import(`./reportes/zonas/module-details`))} />
        <Route path={`${APP_PREFIX_PATH}/reportes/zonas`} component={lazy(() => import(`./reportes/zonas/module`))} />

        {
          // Informacion de usuario
        }
        <Route from={`${APP_PREFIX_PATH}/informacion`} component={lazy(() => import(`./usuario/module`))} />

        <Route path={`${APP_PREFIX_PATH}/trazabilidad`} component={lazy(() => import(`./trazabilidad/index`))} />
        <Route path={`${APP_PREFIX_PATH}/generar/trazabilidad`} component={lazy(() => import(`./trazabilidad/request`))} />
        <Route path={`${APP_PREFIX_PATH}/eventos`} component={lazy(() => import(`./catEventos`))} />
        <Route path={`${APP_PREFIX_PATH}/evento/crear`} component={lazy(() => import(`./catEventos/create`))} />
        <Route path={`${APP_PREFIX_PATH}/evento/editar/:idDoc`} component={lazy(() => import(`./catEventos/edit`))} />

        {
          //Gastos
        }
        <Route path={`${APP_PREFIX_PATH}/gastos/nuevo`} component={lazy(() => import(`./gastos/module-nuevo`))} />
        <Route path={`${APP_PREFIX_PATH}/gastos/editar/:content/:iv`} component={lazy(() => import(`./gastos/module-editar`))} />
        <Route path={`${APP_PREFIX_PATH}/gastos`} component={lazy(() => import(`./gastos/module`))} />


        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);