import React from "react";
import { Link } from "react-router-dom";
import { Menu, Grid } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import navigationConfig from "configs/NavigationConfig";
import { connect } from "react-redux";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from 'utils'
import { onMobileNavToggle } from "redux/actions/Theme";
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import { BookOutlined } from '@ant-design/icons';

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {
	const { sideNavTheme, routeInfo, hideGroupTitle, localization, onMobileNavToggle } = props;
	const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')
	const closeMobileNav = () => {
		if (isMobile) {
			onMobileNavToggle(false)
		}
	}
  const bitacoras = [
    {
      title: 'Control de Calidad',
      path: `${APP_PREFIX_PATH}/control`,
    },
    {
      title: 'Almacenamiento-Producto',
      path: `${APP_PREFIX_PATH}/almacen`,
    },
    {
      title: 'Lavado-Enjuagado',
      path: `${APP_PREFIX_PATH}/lavado`,
    },
    {
      title: 'Limpieza-Instalaciones',
      path: `${APP_PREFIX_PATH}/limpiezaInstalaciones`,
    },
    {
      title: 'Limpieza-Equipo',
      path: `${APP_PREFIX_PATH}/limpiezaEquipo`,
    },
    {
      title: 'Fumigacion',
      path: `${APP_PREFIX_PATH}/fumigacion`,
    },
    {
      title: 'Revision-Higiene',
      path: `${APP_PREFIX_PATH}/higiene`,
    },
  ]

  const coleccion = [
    {
      title: 'Promociones',
      path: `${APP_PREFIX_PATH}/promociones`,
    },
    {
      title: 'Cupones',
      path: `${APP_PREFIX_PATH}/cupones`,
    },
    {
      title: 'Empresa',
      path: `${APP_PREFIX_PATH}/empresa`,
    },
    {
      title: 'Cliente',
      path: `${APP_PREFIX_PATH}/cliente`,
    },
    {
      title: 'Vendedor',
      path: `${APP_PREFIX_PATH}/vendedor`,
    },
  ]

  const catalogos = [
    {
      title: 'Producto',
      path: `${APP_PREFIX_PATH}/producto`,
    },
    /*{
      title: 'Encuestas',
      path: `${APP_PREFIX_PATH}/encuestas`,
    },*/
  ]

  const gastos = [
    {
      title: 'Gastos',
      path: `${APP_PREFIX_PATH}/gastos`,
    },
    {
      title: 'Nuevo Gasto',
      path: `${APP_PREFIX_PATH}/gastos/nuevo`,
    },
  ]


  const ventas = [
    {
      title: 'Pedidos',
      path: `${APP_PREFIX_PATH}/ventas/pedidos`,
    },
  ]
  const reportes = [
    {
      title: 'Clientes Nuevos',
      path: `${APP_PREFIX_PATH}/reportes/clientes-nuevos`,
    },
    {
      title: 'Clientes Atencion',
      path: `${APP_PREFIX_PATH}/reportes/clientes-atencion`,
    },
    {
      title: 'Promedio compras',
      path: `${APP_PREFIX_PATH}/reportes/promedio-compras`,
    },
    {
      title: 'Zonas',
      path: `${APP_PREFIX_PATH}/reportes/zonas`,
    },
    {
      title: 'Ventas',
      path: `${APP_PREFIX_PATH}/reportes/reporte`,
    },
    {
      title: 'Balance',
      path: `${APP_PREFIX_PATH}/reportes/balance`,
    },
  ]

  const qr = [
    {
      title: 'Generar QR por URL',
      path: `${APP_PREFIX_PATH}/qr/generar-by-url`
    },
    {
      title: 'Generar PDF de QRs',
      path: `${APP_PREFIX_PATH}/qr/generar-ids`
    },
  ]

  const whatsapp = [
    {
      title: 'Conectar Whatsapp',
      path: `${APP_PREFIX_PATH}/whatsapp`
    },
    {
      title: 'Escribir mensaje',
      path: `${APP_PREFIX_PATH}/whatsapp/mensaje`
    },

  ]

  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
      mode="inline"
      style={{ height: "100%", borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? "hide-group-title" : ""}
    >
     {/* <SubMenu
          key="bitacoras"
          title={
            <span>
              <BookOutlined />
              <span>Bitacoras</span>
            </span>
          }
      >
        {
          bitacoras.map((bitacoras)=> (
            <Menu.Item>
              <span>{bitacoras.title}</span>
              <Link onClick={() => closeMobileNav()} to={bitacoras.path} />
            </Menu.Item>
          ))
        }
      </SubMenu>*/}

      <SubMenu
          key="coleccion"
          title={
            <span>
              <BookOutlined />
              <span>Coleccion</span>
            </span>
          }
      >
        {
          coleccion.map((coleccion)=> (
            <Menu.Item>
              <span>{coleccion.title}</span>
              <Link onClick={() => closeMobileNav()} to={coleccion.path} />
            </Menu.Item>
          ))
        }
      </SubMenu>

      <SubMenu
          Key="catalogos"
          title={
            <span>
              <BookOutlined/>
              <span>Catalogos</span>
            </span>
          }
      >
        {
          catalogos.map((catalogos)=> (
            <Menu.Item>
              <span>{catalogos.title}</span>
              <Link onClick={() => closeMobileNav()} to={catalogos.path} />
            </Menu.Item>
          ))
        }
      </SubMenu>

      <SubMenu
          key="ventas"
          title={
            <span>
              <BookOutlined />
              <span>Ventas</span>
            </span>
          }
      >
        {
          ventas.map((venta)=> (
            <Menu.Item>
              <span>{venta.title}</span>
              <Link onClick={() => closeMobileNav()} to={venta.path} />
            </Menu.Item>
          ))
        }
      </SubMenu>
      <SubMenu
          key="reportes"
          title={
            <span>
              <BookOutlined />
              <span>Reportes</span>
            </span>
          }
      >
        {
          reportes.map((reporte)=> (
            <Menu.Item>
              <span>{reporte.title}</span>
              <Link onClick={() => closeMobileNav()} to={reporte.path} />
            </Menu.Item>
          ))
        }
      </SubMenu>

      <SubMenu
          key="qr"
          title={
            <span>
              <BookOutlined />
              <span>Código QR</span>
            </span>
          }
      >
        {
          qr.map((qr)=> (
            <Menu.Item>
              <span>{qr.title}</span>
              <Link onClick={() => closeMobileNav()} to={qr.path} />
            </Menu.Item>
          ))
        }
      </SubMenu>

      <SubMenu
          key="whatsapp"
          title={
            <span>
              <BookOutlined />
              <span>Whatsapp</span>
            </span>
          }
      >
        {
          whatsapp.map((w)=> (
            <Menu.Item>
              <span>{w.title}</span>
              <Link onClick={() => closeMobileNav()} to={w.path} />
            </Menu.Item>
          ))
        }
      </SubMenu>

      <SubMenu
          Key="gastos"
          title={
            <span>
              <BookOutlined/>
              <span>Gastos</span>
            </span>
          }
      >
        {
          gastos.map((gastos)=> (
            <Menu.Item>
              <span>{gastos.title}</span>
              <Link onClick={() => closeMobileNav()} to={gastos.path} />
            </Menu.Item>
          ))
        }
      </SubMenu>



      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <Menu.ItemGroup
            key={menu.key}
            title={setLocale(localization, menu.title)}
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  key={subMenuFirst.key}
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      {subMenuSecond.icon ? (
                        <Icon type={subMenuSecond?.icon} />
                      ) : null}
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link onClick={() => closeMobileNav()} to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link onClick={() => closeMobileNav()} to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </Menu.ItemGroup>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link onClick={() => closeMobileNav()} to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const TopNavContent = (props) => {
  const { topNavColor, localization } = props;
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} />
  ) : (
    <TopNavContent {...props} />
  );
};

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
