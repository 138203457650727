import React, { Component, useState, useEffect } from 'react';
import { SettingOutlined, BellOutlined, PoweroffOutlined } from '@ant-design/icons';
import { Drawer, Menu } from 'antd';
import ThemeConfigurator from './ThemeConfigurator';
import { connect } from "react-redux";
import { Badge } from 'antd';
import { signOut } from 'redux/actions/Auth';
import { useHistory } from "react-router-dom";
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import axios from 'configs/axiosConfig';

const NavPanel = ({signOut}) => {

  let history = useHistory();
  
  const [state, setState] = useState(false);
  const [total, setTotal] = useState(0);

  const showDrawer = () => {
    setState(true);
  };

  const onClose = () => {
    setState(false);
	};

  const singOutDeleteCache = async () => {
		try{
			const idToken = localStorage.getItem('auth_token');
			const deleteCache = await axios.get(`/rest/cierra-cache/${idToken}`);
			signOut();
		}catch(e){
			signOut();
		}
	}

  return (
    <>
        <Menu mode="horizontal">

          <Menu.Item onClick={singOutDeleteCache}>
            <PoweroffOutlined/> Cerrar Sesión
          </Menu.Item>
        </Menu>
        <Drawer
          title="Theme Config"
          placement="right"
          width={350}
          onClose={onClose}
          visible={state}
        >
          <ThemeConfigurator/>
        </Drawer>
      </>
  );
}

const mapStateToProps = ({ theme }) => {
  const { locale } =  theme;
  return { locale }
};

export default connect(mapStateToProps, {signOut})(NavPanel);