import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter as Router } from 'react-router-dom';
import Views from './views';
import { Route, Switch } from 'react-router-dom';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from './configs/AppConfig';
import firebaseConfig from './configs/FirebaseConfig';
import firebase from 'firebase';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';






const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};



if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}else {
  firebase.app(); // if already initialized, use that one
}
const mensajeando = firebase.messaging();
mensajeando.requestPermission()
.then(function(){
  console.log('Have permission');
  return mensajeando.getToken({vapidKey: 'BB1sT_cjOgHMWdLBZeyaq5zJlYo0fphTl4US7-y2ktAIVw7MtIHC5eQxv7z596Edc8oZEK4jLvSLCWOuEHRS7kM'});
})
.then(function(token){
  console.log(token);
})
.catch(function(err){
  console.log('Usted No quiere notificaciones');
})


mensajeando.onMessage((payload) => {
  console.log('Message received. ', payload);
  toast("Priority:"+payload.priority +" - Title:"+payload.notification.title + " - Body: "+payload.notification.body);
  // ...
});


function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme} insertionPoint="styles-insertion-point">
          <Router>
            <Switch>
              <Route path="/" component={Views}/>
            </Switch>
          </Router>
        </ThemeSwitcherProvider>
      </Provider>
      <ToastContainer />
    </div>
  );
}

export default App;
