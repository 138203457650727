import { 
  DashboardOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const dashBoardNavTree = [
{
  key: 'usuario',
  path: `${APP_PREFIX_PATH}/informacion`,
  title: 'Información',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: []
},
// {
//   key: 'home',
//   path: `${APP_PREFIX_PATH}/home`,
//   title: 'home',
//   icon: DashboardOutlined,
//   breadcrumb: false,
//   submenu: []
// },
// {
//   key: 'traceability',
//   path: `${APP_PREFIX_PATH}/trazabilidad`,
//   title: 'trazabilidad',
//   icon: DashboardOutlined,
//   breadcrumb: false,
//   submenu: []
// },
// {
//   key: 'catEvents',
//   path: `${APP_PREFIX_PATH}/eventos`,
//   title: 'catalogo de eventos',
//   icon: DashboardOutlined,
//   breadcrumb: false,
//   submenu: []
// }
]

const navigationConfig = [
  //...dashBoardNavTree
]

export default navigationConfig;
